import React, { Component } from "react";
 
class Help extends Component {
  render() {
    return (
      <div className ="container" >
   <p><b>Welcome to Manblay market place Help Center</b></p> 
   <p><b>Navigating Our Marketplace</b></p>
Whether you're a seasoned shopper or a first-time visitor, our Help Center is designed to assist you in navigating our platform. Explore the following sections for valuable information:
<br/>
<p><b>1. Browsing and Searching</b></p>
Learn how to efficiently browse and search for your favorite food ingredients and general goods on our platform.
<br/>

<p><b>2. Placing Orders</b></p>
Making a purchase is simple and secure. Follow these steps to place an order and complete your transaction.
<br/>

<p><b>3. Account Management</b></p>
Your account is your personalized space on our website. Manage your details, track orders, and update preferences effortlessly.
<p></p>

<p><b>Frequently Asked Questions</b></p>
Q: How can I track my order?<br/>
A: Once your order is confirmed, you'll receive a tracking number via email. Use this number to track your order in real-time on our website.
<br/>
Q: What payment options do you accept?<br/>
A: We accept a variety of payment methods, including credit/debit cards and digital wallets. Visit our [Payment Options] page for details.
<br/>
Q: What do I do if an item is out of stock?<br/>
A: If an item is temporarily out of stock, you can sign up for notifications to be informed when it becomes available again.
<br/>
<p><b>Contact Us</b></p>
If you have any questions or need assistance, our customer support team is here to help.
<br/>
Live Chat: [in progress]<br/>
Email: info.manblay@gmail.com<br/>
Phone: 07377040394<br/>
<p><b>Returns and Refunds</b></p>
Learn about our return policy and how to request a refund if you encounter any issues with your order.
<p></p>

<p><b>Feedback</b></p>
We value your feedback! If you have suggestions for improvement, want to share your shopping experience, or have ideas for new products, please let us know.
<br/>
Thank you for choosing Manblay market place. We're committed to providing a seamless and enjoyable shopping experience for all our customers.
<p></p>
      </div>
    );
  }
}
 
export default Help;
import React, { Component } from 'react';

class InvoiceDataTable extends Component {
    render() {
        return ( 
            <div>                 
            <tr>
                <th>Name:</th>
                <td> {this.props.obj.created_at}</td>
            </tr><tr>
                <th>Mobile:</th>
                <td>{this.props.obj.title}</td>
            </tr>
            <tr>
                <th>Name:</th>
                <td>{this.props.obj.firstName}</td>
            </tr><tr>
                <th>Mobile:</th>
                <td>{this.props.obj.lastName}</td>
            </tr>
            <tr>
                <th>Name:</th>
                <td>{this.props.obj.email}</td>
            </tr><tr>
                <th>Mobile:</th>
                <td> {this.props.obj.phoneNumber}</td>
            </tr>
            <tr>
                <th>Name:</th>
                <td> {this.props.obj.DishName}</td>
            </tr>
            <tr>
                <th>Mobile:</th>
                <td> {this.props.obj.Item1Name}</td>
            </tr>
            <tr>
                <th>Name:</th>
                <td> {this.props.obj.Item1Price}</td>
            </tr><tr>
                <th>Mobile:</th>
                <td> {this.props.obj.Item1Qty}</td>
            </tr>
            <tr>
                <th>Mobile:</th>
                <td> {this.props.obj.Item2Name}</td>
            </tr>
            <tr>
                <th>Name:</th>
                <td> {this.props.obj.Item2Price}</td>
            </tr><tr>
                <th>Mobile:</th>
                <td> {this.props.obj.Item2Qty}</td>
            </tr>
            <tr>
                <th>Mobile:</th>
                <td> {this.props.obj.Item3Name}</td>
            </tr>
            <tr>
                <th>Name:</th>
                <td> {this.props.obj.Item3Price}</td>
            </tr><tr>
                <th>Mobile:</th>
                <td> {this.props.obj.Item3Qty}</td>
            </tr>
            <tr>
                <th>Mobile:</th>
                <td> {this.props.obj.Item4Name}</td>
            </tr>
            <tr>
                <th>Name:</th>
                <td> {this.props.obj.Item4Price}</td>
            </tr><tr>
                <th>Mobile:</th>
                <td> {this.props.obj.Item4Qty}</td>
            </tr>
            <tr>
                <th>Mobile:</th>
                <td> {this.props.obj.Item5Name}</td>
            </tr>
            <tr>
                <th>Name:</th>
                <td> {this.props.obj.Item5Price}</td>
            </tr><tr>
                <th>Mobile:</th>
                <td> {this.props.obj.Item5Qty}</td>
            </tr>
            <tr>
                <th>Mobile:</th>
                <td> {this.props.obj.Item6Name}</td>
            </tr>
            <tr>
                <th>Name:</th>
                <td> {this.props.obj.Item6Price}</td>
            </tr><tr>
                <th>Mobile:</th>
                <td> {this.props.obj.Item6Qty}</td>
            </tr>
            <tr>
                <th>Name:</th>
                <td>  {this.props.obj.InvoiceNumber}</td>
            </tr><tr>
                <th>Mobile:</th>
                <td> {this.props.obj.Grand_total}</td>
            </tr>
            <tr>
                <th>Name:</th>
                <td> {this.props.obj.CatererCompanyName}</td>
            </tr><tr>
                <th>Mobile:</th>
                <td> {this.props.obj.total}</td>
            </tr>
            <tr>
                <th>Name:</th>
                <td>{this.props.obj.VAT}</td>
            </tr><tr>
               
            </tr>
            </div>
        );
    }
}

export default InvoiceDataTable;
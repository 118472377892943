import React, { Component } from "react";
 
class Help extends Component {
  render() {
    return (
      <div className ="container" >
  
<p></p>
Welcome to Manblay assembly Help Center<br/>
<p></p>
<p><b>Getting Started with Product Assembly<br/></b></p>

Welcome to our Help Center! Whether you've just ordered a product assembly service or are considering it, this guide will walk you through the process and answer common questions. If you need further assistance, our support team is ready to help.
<br/>
<p><b>Placing an Assembly Order<br/></b></p>
Select Your Product: Choose the product you need assembling from our catalog or provide details about a custom item.
<br/>
<p><b>Add to Cart:</b></p> Once you've selected your product, add the assembly service to your cart.
<br/>
<p><b>Checkout: </b></p>Proceed to checkout, and provide the necessary delivery and contact information.
<br/>
<p><b>Payment:</b></p> Complete the payment process to confirm your order.
<br/>
<p><b>Tracking Your Assembly Service</b></p>
<br/>
Once your order is confirmed, you can track the assembly process through our online portal.
<br/>
<p><b>Order Confirmation Email:</b></p> <br/>You'll receive an email confirming your order with a unique order ID.
<br/>
<p><b>Real-time Updates:<br/></b></p> Track the status of your assembly in real-time through your account dashboard.
<br/>
<p><b>Assembly Completion Notification: </b></p><br/>Receive a notification when your product assembly is complete, along with any relevant delivery information.
<br/>
<p><b>Frequently Asked Questions<br/></b></p>
Q: Can I schedule a specific assembly date and time?<br/>
A: Yes, during the checkout process, you can specify your preferred assembly date and time, subject to availability.
<br/>
Q: What if I need to reschedule or cancel my assembly service?<br/>
A: You can manage your assembly appointments through your account dashboard. For further assistance, contact our support team.
<br/>
Q: Is there a warranty on the assembly work?<br/>
A: We stand behind the quality of our assembly services. If you encounter any issues, please contact us, and we'll address them promptly.
<br/>
<p><b>Contact Us<br/></b></p>
If you have any questions or need assistance with your product assembly service, our customer support team is here to help.
<br/>
Live Chat: in progress<br/>
Email: info.manblay@gmail.com<br/>
Phone: 07377040394<br/>
<p><b>Feedback<br/></b></p>
Your feedback is valuable to us. If you have suggestions for improvement or would like to share your experience, please let us know.
<br/>
<p></p>
Thank you for choosing Manblay assembly. We're dedicated to ensuring your product is assembled with care and precision.
<br/>
<p></p>
      </div>
    );
  }
}
 
export default Help;
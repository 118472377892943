import React, { Component } from "react";
import axios from "axios";
import { withTranslation } from "react-i18next";
import DataTable from './MessageDataTable';
import LocalStorageService from '../../services/localStorageService';
import GetUrl from "../services/urlServiceCommodities";

class MyMessagePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      usersMessages: [],
      xYZabcdefghijklmnopqrsfghijVWXYcdestuvwxyz0192 : "", 
      qRSTUVWXYZabcdefghijklmnopqrstuvwxyz012 : ""
     };   
}

componentDidMount() {
   this.retrieveMessages();
}

retrieveMessages(){
  this.setState({   
  // xYZabcdefghijklmnopqrsfghijVWXYcdestuvwxyz0192 : this.getEmail()?this.getEmail().split('@')[0]:"",
  // qRSTUVWXYZabcdefghijklmnopqrstuvwxyz012 : this.getEmail()?this.getEmail().split('@')[1]:""
  }); //email part1, email part2, 
  
  //const body = {this.state.xYZabcdefghijklmnopqrsfghijVWXYcdestuvwxyz0192, qRSTUVWXYZabcdefghijklmnopqrstuvwxyz012}; 
  
 // const body2 = {XYZabcdefghijklmnopqrsfghijVWXYcdestuvwxyz0192, QRSTUVWXYZabcdefghijklmnopqrstuvwxyz012}; 
  
  const API_PATH = this.getApiPath();
      axios({
        method: "post",
        url: `${API_PATH}`,
        data: this.getEmail()
      })
        .then((result) => {
          if (result.status === 200) { 
          this.setState({ usersMessages: result.data });  
            console.log("response: ", result.data)  
          }
        })
        .catch(function (error) {    
          console.log(error);
        });    
   
}

dataTable() {
    return this.state.usersMessages.map((data, i) => {
      console.log("data: ", data)
        return <DataTable obj={data} key={i} />;
    });
}


 getUsertype =() =>{
  return LocalStorageService("get", "userType"); 
}; 

 getToken (){
  return LocalStorageService("get", "token"); 
}; 

getEmail (){
  return LocalStorageService("get", "userEmail"); 
}; 


 getApiPath = () =>  {   
  return GetUrl("retrieveBuyerMessageshairstyle");
};


  
  
  render() {
    const { t } = this.props;
    const userIsCustomer = this.getUsertype()=='C';
    const token = this.getToken();

    console.log("table data: ", this.dataTable())
    return (      
      <div>  {token && userIsCustomer &&(
     <table className="table table-striped table-info">
                        <thead className="thead-info">
                             <tr>
                                <td><strong>Id</strong></td>
                                <td><strong>Date created</strong></td>
                                <td><strong>Gender</strong></td>
                                <td><strong>First Name</strong></td>
                                <td><strong>Last Name</strong></td>
                                <td><strong>Email</strong></td>
                                <td><strong>Phone Number</strong></td>
                                <td><strong>The Message</strong></td>
                                <td><strong>Response</strong></td>
                                <td><strong>Read status</strong></td>
                            </tr>
                        </thead>
                        <tbody>
                            {this.dataTable()}
                        </tbody>
                    </table>  )}     
      </div>
    );
  }
}
 
export default withTranslation()(MyMessagePage);
import React, { Suspense,  useState, useEffect  } from "react";
import i18n from "./services/i18n";
import { withTranslation } from "react-i18next";
import Main from "./mainbody";
import Footer from "./footer/Footer";
import "./mainApp.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./Stylesheets/StyleSheet.css";
import NavBar from "./responsive/Navbar";
import NavBarCatering from "./clientappCatering/responsive/catering/NavBarCatering";
import NavbarHairTechnician from "./clientappHairtechnicians/responsive/NavbarHairTechnician";
import NavbarCommodity from "./clientappCommodities/responsive/NavbarCommodity";
import NavbarAssembly from "./clientappAssemblingTechnicians/responsive/NavbarAssembly";
import LocalStorageService from "./services/localStorageService";

function App({ t }) {
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };


  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [hasServiceCategory, setHasServiceCategory] = useState(false);

const [time, setTime] = useState(Date.now());

useEffect(() => {
  const interval = setInterval(() => setTime(Date.now()), 300);
  return () => {
    clearInterval(interval);
  };
}, []);

useEffect(() => {
  // Check if the current URL is https://londonrestaurants.info
  if (window.location.hostname === 'https://londonrestaurants.info') {
    // Redirect to https://manblaysavour.com/#/homecatering
    window.location.replace('https://manblaysavour.com/#/homecatering');
  }
}, []);

  var servicecategory = LocalStorageService("get","servicecategory") ;
  return (
    <div>
    <div >
      <div className = "header-akwaba">
      <NavBar />
      {/* {(servicecategory!='hairstyle'&& servicecategory  !='catering' && servicecategory !='commodities' && servicecategory !='assembling')&& (<NavBar /> )} 
      {servicecategory=='hairstyle' &&( <NavbarHairTechnician/>)}
      {servicecategory=='catering' &&(<NavBarCatering/>)}
      {servicecategory=='commodities' &&(<NavbarCommodity/>)} 
      {servicecategory=='assembling' &&(<NavbarAssembly/>)}        */}
      </div>
      <div className = "">
      <Suspense fallback={<div>Loading</div>}>
      <Main />
      </Suspense>
      </div>
    </div>
    <Footer />
    </div>
  );
}

export default withTranslation()(App);

import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import LoadMoreAssets from './loadPages/LoadMoreAssetsRoot';
import LocalStorageService from "../services/localStorageService";
  import NavbarHairTech from "./responsive/NavbarHairTechnician";

class HomeHairs extends Component {
  render() {    
    const { t } = this.props; 
    return (
      <div>     
    <div className="content-akwaba" > 
    <p>Best beautity technicians in the world</p>
      <span><NavbarHairTech/> </span>  
         <LoadMoreAssets/> 
        <p></p>
      </div>
      </div>
    );
  }
}

export default withTranslation() (HomeHairs);

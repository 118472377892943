import React, { useState, useEffect } from "react";
import axios from "axios";
import {NavLink, HashRouter } from "react-router-dom";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
import { Row, Col } from "react-bootstrap";
import { useTranslation  } from "react-i18next";
import { createBrowserHistory } from 'history';
import AssetOptionalVideos from './AssetOptionalVideos' ;
import CommentOnProperty from '../pages/comments/CommentOnProperty';
import Accordion from 'react-bootstrap/Accordion';
import Card from "react-bootstrap/Card";
import GetUrl from "../services/urlService";
import LocalStorageService from '../../services/localStorageService';
import FoodMenuePage from "../admin/foodMenue/FoodMenuePage";
import GenerateOrdersPage from  "../admin/orders/GenerateOrder";
import ReactPlayer from "react-player";
//https://codesandbox.io/s/c53q2?file=/src/App.js:58-172
import GetEnvironment  from "../services/getEnvironment";


  const AssetDetailPageLive = (props) => { 
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [imageextension, setImageextension] = useState('');
  const [mainImage, setMainImage] = useState('');
  const [firstOpt, setFirstOpt] = useState('');
  const [secondOpt, setSecondOpt] = useState('');
  const [thirdOpt, setThirdOpt] = useState('');
  const [assetNote, setAssetNote] = useState('');
  const [assetName, setAssetName] = useState('');
  const [assetPrice, setAssetPrice] = useState('');
  const [assetDescription, setAssetDescription] = useState('');
  const [replyerName, setReplyerName] = useState('');
  const [reply, setReply] = useState('');
  const[propertyId, setpropertyId]= useState('');
  const[propertyName, setpropertyName]= useState('');
  const [mainImagesrc, setMainImagesrc] = useState();
  const [firstOptsrc, setFirstOptsrc] = useState();
  const [secondOptsrc, setSecondOptsrc] = useState();
  const [thirdOptsrc, setThirdOptsrc] = useState();
  const [contactEmail, setContactEmail] = useState();
  const [videoPath, setVideoPath] = useState();
  const [videoPathIsNullOrEmpty, setVideoPathStatus] = useState(false);
  const [hasMenue, setHasMenue] = useState(false);
  const [post, setPost] = useState([]);
  const [productid, setProductid] = useState();
  const [env,  setEnv] = useState('');
  const [displaycarousel, setdisplaycarousel] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const[sellerPhoneNumber, setSellerPhoneNumner]= useState();
  const[address, setAddress ]= useState();
  const[sellerName, setSellerName ]= useState();
 
  useEffect(() => { 
    setTimeout(() =>  getAssetDetailsById(), 100);
  }, []);

  const { t } = useTranslation();
   
  useEffect(() => {
    setEnv(LocalStorageService("get", "environment")??GetEnvironment())
  }, []) ;

  useEffect(() => {  
    setIsVideoLoaded(true);  
}, []);
 
useEffect(() => {
  LocalStorageService("clear");
  }, []);

  useEffect(() => {
    setTimeout(() =>  setLocalStorageFromParent(), 100);    
  }, []);

  function getApiPath () {     
    return GetUrl("getAssetDetailById");   
  }
  
  
  const setLocalStorageFromParent = () => {
    LocalStorageService("set","mainimage", props.location.state?.PathMainImage);
    LocalStorageService("set","opt1image", props.location.state?.PathFirstOptionalImage);
    LocalStorageService("set","opt2image", props.location.state?.PathSecondOptionalImage);
    LocalStorageService("set","opt3image", props.location.state?.PathThirdOptionalImage);
    LocalStorageService("set","video", props.location.state?.Videopath);
    LocalStorageService("set","assetNote", props.location.state?.Sellernote);
    LocalStorageService("set","assetDescription", props.location.state?.Description);
    LocalStorageService("set","assetName", props.location.state?.ShopName);
    LocalStorageService("set","assetPrice", props.location.state?.Price);
    LocalStorageService("set","reply", props.location.state?.reply);
    LocalStorageService("set","replyerName", props.location.state?.replyerName); 
    LocalStorageService("get","productid")??LocalStorageService("set","productid", props.location.state?.productId); 
    LocalStorageService("set","prodName", props.location.state?.productName); 
    LocalStorageService("set","hasMenue", props.location.state?.MenueStatus);    
    LocalStorageService("set", "sellerPhoneNumber",props.location.state?.SellerPhone);
    LocalStorageService("set", "sellerEmail",props.location.state?.SellerEmail);
    LocalStorageService("set", "shopName",props.location.state?.ShopName);
  }

  const prodid = window.location.href.split('?')[1];

  const  getAssetDetailsById = () =>{   
    const url = getApiPath();   
    axios.post(url, {         
       Id: prodid
      //Id: props.location.state?.productId??LocalStorageService("get","productid")   
      })
      .then(res => { 
        if(res.data !=="no data") 
        {
          setPost(res.data);
        }  
        
       
  //uncomment from here
  LocalStorageService("set","mainimage", res.data[0].PathMainImage);
  LocalStorageService("set","opt1image", res.data[0].PathFirstOptionalImage);
  LocalStorageService("set","opt2image", res.data[0].PathSecondOptionalImage);
  LocalStorageService("set","opt3image", res.data[0].PathThirdOptionalImage);
  LocalStorageService("set","video", res.data[0].Videopath);
  LocalStorageService("set","assetNote", res.data[0].Sellernote);
  LocalStorageService("set","assetDescription", res.data[0].Description);
  LocalStorageService("set","assetName", res.data[0].ShopName);
  LocalStorageService("set","assetPrice", res.data[0].Price);
  LocalStorageService("set","reply", res.data[0].reply);
  LocalStorageService("set","replyerName", res.data[0].replyerName); 
  LocalStorageService("set","productId", res.data[0].productId); 
  LocalStorageService("set","prodName", res.data[0].productName); 
  LocalStorageService("set","hasMenue", res.data[0].MenueStatus);    
  LocalStorageService("set", "sellerPhoneNumber",res.data[0].SellerPhone);
  LocalStorageService("set", "sellerEmail",res.data[0].SellerEmail);
  LocalStorageService("set", "shopName",res.data[0].ShopName);
  LocalStorageService("set", "sellerNameApi",res.data[0].ShopOwnerName ); 
 
  const imagepaths = props.location.state; 

  console.log({imagepaths});

  const mainimagefromApi =  res.data[0].PathMainImage;
  const opt1imagefromApi = res.data[0].PathFirstOptionalImage;
  const opt2imagefromApi = res.data[0].PathSecondOptionalImage;
  const opt3imagefromApi = res.data[0].PathThirdOptionalImage;
  const videofromApi  = res.data[0].Videopath;
  const assetNotefromApi = res.data[0].Sellernote;
  const assetDescriptionfromApi  = res.data[0].Description;
  const assetNamefromApi = res.data[0].ShopName;
  const assetPricefromApi = res.data[0].Price;
  const replyfromApi = res.data[0].reply;
  const replyerNamefromApi = res.data[0].replyerName; 
  const productIdfromApi = res.data[0].productId;
  const productNamefromApi = res.data[0].productName; 
  const hasMenuefromApi = res.data[0].MenueStatus;    
  const sellerPhoneNumberfromApi = res.data[0].SellerPhone;
  const sellerEmailfromApi = res.data[0].SellerEmail; 
  const menuNamefromApi  = res.data[0].productName;
  const shopNamefromApi  = res.data[0].ShopName;
  const addressfromAPi  = res.data[0].Address;
  const sellerNamefromApi  = res.data[0].SellerName;
 
 
   ////set the picture paths
  
     const assetName = imagepaths?.ShopName??assetNamefromApi; 
     const assetPrice = imagepaths?.assetPrice??assetPricefromApi;
     const assetNote = imagepaths?.assetNote??assetNotefromApi;
     const assetDescription = imagepaths?.assetDescription??assetDescriptionfromApi;
     const replyerName = imagepaths?.replyerName??replyerNamefromApi;
     const reply = imagepaths?.reply??replyfromApi; 
     const videoPath = imagepaths?.video??videofromApi;
     const propId = imagepaths?.productId??productIdfromApi;
     const propName = productNamefromApi;
     const isMenueAvailable = imagepaths?.hasMenue??hasMenuefromApi;

     const mainimage = `/${res.data[0].PathMainImage}`;
     const opt1image = `/${res.data[0].PathFirstOptionalImage}`;
     const opt2image = `/${res.data[0].PathSecondOptionalImage}`;
     const opt3image = `/${res.data[0].PathThirdOptionalImage}`;
  
     var videosrc =  videofromApi?? "videos/thesun.earthrotating.mp4video.mp4"
     const video =`/${res.data[0]?.Videopath}`??'';

   setpropertyId(propId);
   setpropertyName(propName);
   setMainImagesrc(mainimage);
   setFirstOptsrc(opt1image); 
   setSecondOptsrc(opt2image);  
   setThirdOptsrc(opt3image); 
   setVideoPath(video);
   setAssetNote(assetNote);
   setAssetName(assetName);
   setAssetPrice(assetPrice);
   setAssetDescription(assetDescription); 
   setReplyerName(replyerName);
   setReply(reply);
   setSellerPhoneNumner(sellerPhoneNumberfromApi);
   setAddress(addressfromAPi);
   setSellerName(sellerNamefromApi);
   setContactEmail(sellerEmailfromApi);  

   setHasMenue(isMenueAvailable);

   let isNullOrEmptyvideoPath = videoPath===''||videoPath===null;
   setVideoPathStatus(isNullOrEmptyvideoPath); 
   setdisplaycarousel(true);
 
       })
       .catch((e) => console.log(e))    
  }

  const dimensions = {
    width:"100%",
    height:"100%",
  }
  const items = [      
    {   
      src: mainImagesrc,    
      altText: "Slide 1",
      caption: "",   
      width:dimensions.width,
      height:dimensions.height,
    },
    {
      src: firstOptsrc,
      altText: "Slide 2",
      caption: "",    
      width:dimensions.width,
      height:dimensions.height,
    },
    {
      src: secondOptsrc,
      altText: "Slide 3",
      caption: "",    
      width:dimensions.width,
      height:dimensions.height,
    },
    {
      src: thirdOptsrc,
      altText: "Slide 4",
      caption: "",     
      width:dimensions.width,
      height:dimensions.height,    
    }
  ];
  
  
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.filter(img => img).map((item) => { 
    const  backgroundStyle = {
      color: 'white',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'scroll',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      width: "100%",
      height: "500px",
      backgroundImage: `url(${item.src})` 
   };    
    return (  
      
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >        
     <CarouselCaption
          captionText={item.caption}         
        />
       <div style={backgroundStyle}> </div> 
       <div></div>    
      </CarouselItem>   
      

    );
  });
 
 
  const [fname, setFirstName] = useState('');
  const [lname, setLastName] = useState('');
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [phonenumber, setPhoneNumber] = useState('');
  const [reason, setReason] = useState('I am interested in this dish. I wish to contact you to talk about it.');
  const [messageSent, setMessageSent] = useState('');
  const [clickedButtonButNotPosted, setClickedButtonButNotPosted] = useState('');
  const [clickedNotPostedMessage, setClickedNotPostedMessage] = useState('');
  const [fnameerrormsg, setFirstNameerrormsg] = useState('');
  const [lnameerrormsg, setLastNameerrormsg] = useState('');
  const [titleerrormsg, setTitleerrormsg] = useState('');
  const [emailerrormsg, setEmailerrormsg] = useState('');
  const [phonenumbererrormsg, setPhoneNumbererrormsg] = useState('');
  const [reasonerrormsg, setReasonerrormsg] = useState('');
  const [propertyid, setPropertyid] = useState(localStorage.getItem('Id'));
  const [propertyid2, setPropertyid2] = useState('Dish number '+  localStorage.getItem('Id'));
  const [propertyidforbookinginterest, setPropertyidforbookinginterest] = useState('');
  const [type, setType] = useState('Interest');
  const [Id, setId] = useState();


  const getApiPathForBookings = () => { 
    return GetUrl("bookings")
  };

  const handleSubmit = event => {
    console.log('handleSubmit ran');
    event.preventDefault(); // 👈️ prevent page refresh
   const body ={fname, lname, title,email, phonenumber,reason, propertyid, type }; 
    
   setClickedButtonButNotPosted(<p>{t("pages.contact.text.msginvalidform")} </p>);
     if (validate()) {            
      const API_PATH = getApiPathForBookings();
      axios({
        method: "post",
        url: `${API_PATH}`,
        data: body,
      })
        .then((result) => {
          if (result.status === 200) {         
            setFirstName('');
            setLastName('');
            setTitle('');
            setEmail('');
            setPhoneNumber('');
            setReason('');
            setEmailerrormsg('');
            setFirstNameerrormsg('');
            setLastNameerrormsg('');
            setTitleerrormsg('');
            setEmailerrormsg('');
            setPhoneNumbererrormsg('');
            setReasonerrormsg('');
            setMessageSent(true);
            setClickedButtonButNotPosted(false);
            setClickedNotPostedMessage('');            
          }
        })
        .catch(function (error) {    
          console.log(error);
        });     
    } else { 
      setClickedButtonButNotPosted(true);
      setClickedNotPostedMessage(<p>{t("pages.contact.text.msginvalidform")} </p>);  
      console.log("Data was not sent");
      return;
    }
  }

 const  validate = ()=> {
    let isValid = true; 
    if (!title) {
      isValid = false;
      setTitleerrormsg(<p>{t("pages.contact.text.titleinvalidmsg")}</p>);
    }
    
    if (!fname) {
      isValid = false;
      setFirstNameerrormsg(<p>{t("pages.contact.text.firstnameinvalidmsg")}</p>);
    }

    if (!lname) {
      isValid = false;
      setLastNameerrormsg(<p>{t("pages.contact.text.lastnameinvalidmsg")}</p>);
    }

    if (!phonenumber) {
      isValid = false;
      setPhoneNumbererrormsg(<p>{t("pages.contact.text.phoneinvalidmsg")}</p>);
     }
    if (!email) {
      isValid = false;
      setEmailerrormsg(<p>{t("pages.contact.text.emailinvalidmsg")}</p>);
    }

    if (typeof email !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        isValid = false;
        setEmailerrormsg(<p>{t("pages.contact.text.emailpatterninvalidmsg")}</p>);
      }
    }
      
    return isValid;
  };

  return (     
    <div className="Container-detail-page">
      
    {/*   CAROUSEL ROW START HERE */}
    {displaycarousel &&(<Row>
    <Col >

      <div className="carousel-container">
      <span className = "detailed-item-identification-number">
     <p>Dish identification number: {prodid}</p> 
     </span>
     
    <Carousel  className="carousel-panel" activeIndex={activeIndex} next={next} previous={previous}>
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
    </div>
    </Col>    
    </Row> )}
    {/* End carousel row */}
    <p></p>
    <Row> 
    <Col md={{ span: 6, offset: 3 }}>
    <div className="item-detailed-description"> 
       <span className = "detailed-item-description">
        <p>Caterer profile and dish description</p>
       </span>  
       <span>
         <p><b><b>Caterer information </b></b></p>
         <p><b> Name: </b> {sellerName}</p>
         <p><b> Phone:</b> {sellerPhoneNumber}</p>
         <p><b> Email: </b> {contactEmail}</p>
         <p><b> Shop name: </b> {assetName}</p>
         <p><b> Shop address: </b>{address}</p>
         <p><b> Additional note: </b> {assetNote}</p>
       </span>
       <span>
        <p><b><b>Dish  information </b></b> </p>
        <p><b>Name: </b> {propertyName}</p>
        <p><b>Description: </b>{assetDescription}</p>
      </span> 
     </div>    
    </Col>
    </Row> 
    <p></p> 
    <Row>  
    <Col>
         
    {!videoPathIsNullOrEmpty &&(

  <div className="body-pannel">   
      <div className="playerWrapper" style={{opacity: isVideoLoaded ? 1 : 0}}>
          <ReactPlayer
            url={videoPath}
            playing={true}
            controls={true}
            loop={false}
            muted={true}
            playsinline={true}
            onReady={isVideoLoaded}
          />
        </div>
  </div>
  )}
  </Col>   
  </Row>
    <p></p> 
    <Row> 
  <div  className="content-akwaba">
  
   <Accordion defaultActiveKey="0"> 
     
 {hasMenue==1 &&( 
    <Card>
    <Accordion.Toggle as={Card.Header} eventKey="1">
   Check the menue <p><h4>⏬ </h4></p> 
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="1">
      <Card.Body className="content-accordion" >
      <Row>
                   
      <FoodMenuePage></FoodMenuePage>
                 
      </Row> 
      </Card.Body>
      </Accordion.Collapse>
    </Card>
      )}
 
{hasMenue==1 &&( 
    <Card>
    <Accordion.Toggle as={Card.Header} eventKey="2">
     Place order <p><h4>⏬ </h4></p> 
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="2">
      <Card.Body className="content-accordion" >
      <Row>
                   
      <GenerateOrdersPage></GenerateOrdersPage>
                 
      </Row> 
      </Card.Body>
      </Accordion.Collapse>
    </Card>
      )}

{hasMenue!=1 &&( 
    <Card>
    <Accordion.Toggle as={Card.Header} eventKey="2">
   Express interest for this menu by filling and sending the form below to your caterer. 
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="2">
      <Card.Body className="content-accordion" >
      <div className="col-md-9 offset-3">
      <form action="#">      
        <div className="form-group">
        <label>Query type(ignore this field. It is auto filled) </label>
        <input
         readonly 
          id="type"
          name="type"
          type="text"
          value={type}
          onChange={event => setType(event.target.value)}          
          placeholder="Interest"        
        />          
        </div>
        <div className="form-group">
        <label>{t("pages.contact.text.title")} </label>
             <select name= "title" id="title" value={title}  onChange={event => setTitle(event.target.value)} >
                <option value="">{t("pages.contact.default")}</option>
                <option value={t("pages.contact.sir")}>{t("pages.contact.sir")}</option>
                <option value={t("pages.contact.madam")}>{t("pages.contact.madam")}</option>
                <option value={t("pages.contact.unspecified")}>{t("pages.contact.unspecified")}</option>  
                <option value={t("pages.contact.other")}>{t("pages.contact.other")}</option>                
              </select>
          <div className="text-danger">{titleerrormsg}</div>
        </div>

        <div className="form-group">
        <label>{t("pages.contact.text.firstname")} </label>
        <input
          id="first_name"
          name="first_name"
          type="text"
          value={fname}
          onChange={event => setFirstName(event.target.value)}          
          placeholder={t("pages.contact.text.firstnameph")}
        />
          <div className="text-danger">{fnameerrormsg}</div>
        </div>

        <div className="form-group">
         <label>{t("pages.contact.text.lastname")}</label>
        <input
          id="last_name"
          name="last_name"
          type="text"
          value={lname}
          onChange={event => setLastName(event.target.value)}
          placeholder={t("pages.contact.text.lastnameph")}
        />
          <div className="text-danger">{lnameerrormsg}</div>
        </div>

        <div className="form-group">
        <label>{t("pages.contact.text.email")}</label>
        <input
          id="email"
          name="email"
          type="text"
          value={email}
          onChange={event => setEmail(event.target.value)}
          placeholder={t("pages.contact.text.emailph")}
        />
          <div className="text-danger">{emailerrormsg}</div>
      </div>

      <div className="form-group">
              <label>{t("pages.contact.text.phone")}</label>
         <input
          id="phonenumber"
          name="phonenumber"
          type="text"
          onChange={event => setPhoneNumber(event.target.value)}
          placeholder={t("pages.contact.text.phoneph")}
          value={phonenumber}
        />
          <div className="text-danger">{phonenumbererrormsg}</div>
        </div>

        <div className="form-group">
        <label>Dish number</label>
        <input
          readonly 
          id="_id"
          name="propertyid"
          type="text"         
          value={propertyId}          
          onChange={event => setPropertyid(event.target.value)}
          placeholder={propertyId}        
        />            
       </div>


        <div className="form-group">
        <label>Describe what you want(names and quantities)</label>
        <textarea
          id="reason"
          name="reason"
          type="text"
          rows={4}
          value={reason}          
          onChange={event => setReason(event.target.value)}
          placeholder="I am interested in this dish. I would like such and such things, in such quantities each."
        />
          <div className="text-danger">{reasonerrormsg}</div>      
       </div>
     
       <input
              className="btn btn-primary"
              type="submit"
              onClick={(e) =>  {handleSubmit(e)}}
              defaultValue={t("pages.contact.text.submit")}
            />
       
        <div>
               {messageSent && (
                <div>
                  {t("pages.contact.text.thankyou1")} <br />
                  {t("pages.contact.text.thankyou2")} <br />                 
                  {t("pages.contact.text.thankyou3")}{" "}
                </div>
              )}
              
              {clickedButtonButNotPosted && (
                <div>
                  {clickedNotPostedMessage} <br />                  
                </div>
                 )}
         </div>
         </form>
         </div>
        </Card.Body>
    </Accordion.Collapse>
  </Card>
)}
  </Accordion>
 
  </div>
    </Row>
    <p></p>     
    </div>    
  );
};


export default (AssetDetailPageLive);
import React, {Component,  useState, useEffect } from 'react';
import { withTranslation } from "react-i18next";
import LocalStorageService from './services/localStorageService';

class DashboardCarousel extends Component {
  render() {
    const { t } = this.props;
    LocalStorageService("remove","servicecategory")
    return (
      <div>
    
        <div id="marveltech-bod2">
          <div>
        <div id="img1" className="imgbox1">
          <div>         
              <span id="dashboard-home-1">Your Everyday partner</span>
              <span id="dashboard-home-light-1">Amazing Beauty creators</span>
          </div>
       </div>

       <div id="img2" className="imgbox2" >
       <div>         
              <span id="dashboard-home-2">Your unavoidable partner</span>
              <span id="dashboard-home-light-2">Delightfully tasty Food</span>
      </div>
      </div>  

      <div id="img3" className="imgbox3" >
      <div>         
              <span id="dashboard-home-3">Your unavoidable partner</span>
              <span id="dashboard-home-light-3">Affordable Network of Services</span>
      </div>
      </div> 

      {/* <div id="img4" className="imgbox4" >
      <div>         
              <span id="dashboard-home-4">Your unavoidable partner</span>
              <span id="dashboard-home-light-4">We assemble these</span>
      </div>
      </div>  */}

      <p></p>  
        </div>    
      </div> 

      <div>   
     </div> 
      </div> 
    );
  }
}

export default withTranslation() (DashboardCarousel);


import { useTranslation } from 'react-i18next';
import { React,useState,useEffect} from "react";
import axios from "axios";
import Select from 'react-select';
import GetUrl from '../services/urlServiceAssembling';
import LocalStorageService from '../../services/localStorageService';
import {Row, Col} from 'react-bootstrap';

const SendPaymentConfirmation = () => {

  const [title, setTitle] = useState('');
  const [fname, setFirstName] = useState('');
  const [lname, setLastName] = useState('');
  const [mobilenumber, setMobileNumber] = useState('');
  const [paymentId, setPaymentId] = useState('');

  function getApiPath () { 
    return GetUrl("sendPaymentConfirmationcommodities")
  };

  function loginToken (){
    return LocalStorageService("get", "token")
  }; 

 
//  const email = loginEmail();
 const token = loginToken();
    function confirmPayment() {
      const body ={
        title,
        fname,
        lname,
        paymentId
      }
          const API_PATH = getApiPath();
          axios({
            method: "post",
            url: `${API_PATH}`,
            data: body,
          })
            .then((result) => {
              if (result.status === 200) {         
                         
              }
            })
            .catch(function (error) {    
              console.log(error);
            }); 
        }
}

export default SendPaymentConfirmation;
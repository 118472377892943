import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";
import Services from "../clientappHairtechnicians/pages/Services";
import Contact from "../clientappHairtechnicians/pages/messages/SendContactMessage";
import Menue from "../clientappHairtechnicians/pages/Menue";
import References from "../clientappHairtechnicians/pages/References";
import Booking from "../clientappHairtechnicians/pages/Booking";
import Appointments from "../clientappHairtechnicians/pages/Appointments";
import Interets from "../clientappHairtechnicians/pages/Interets";
import RegisterSimple from "../clientappHairtechnicians/registerlogin/RegisterSimple";
import ChangePassword from "../clientappHairtechnicians/registerlogin/ChangePassword";
import Loginscreen from "../clientappHairtechnicians/registerlogin/LoginScreen";
import Login from "../clientappHairtechnicians/registerlogin/Login";
import MessageMyAdmin from "../clientappHairtechnicians/admin/myAdmin/MessageAdminPage";
import MyAdmin from "../clientappHairtechnicians/admin/myAdmin/MyDashBoard";
import ServiceSubscription from "../clientappHairtechnicians/admin/myAdmin/ServiceSubscription";
import Logout from "../clientappHairtechnicians/registerlogin/Logout";
import Partnerservice from "../clientappHairtechnicians/partners/Partnerservice";
import UsefullLinks from "../clientappHairtechnicians/admin/myAdmin/UsefullLinks";
import ThankYouUploaded from "../clientappHairtechnicians/uploadMedia/ThankyouAfterUploaded";
import UploadAssets from "../clientappHairtechnicians/uploadMedia/UploadTextInputs";
import AssetDetailsPageDev from "../clientappHairtechnicians/loadPages/AssetDetailPageDev";
import AssetDetailsPageLive from "../clientappHairtechnicians/loadPages/AssetDetailPageLive";
import UploadVideo from "../clientappHairtechnicians/uploadMedia/UploadVideo";
import UploadPictures from "../clientappHairtechnicians/uploadMedia/UploadPictures";
import ThankyouAfterRegistration from "../clientappHairtechnicians/registerlogin/ThankyouAfterRegistration";
import ThankyouPasswordChanged from  "../clientappHairtechnicians/registerlogin/ThankyouPasswordChanged";
import BuyerService from "../clientappHairtechnicians/buyers/BuyerServices";
import BuyerMessages from "../clientappHairtechnicians/buyers/MyMessagePage";
import BuyerAppointments from "../clientappHairtechnicians/buyers/MyAppointments";
import BuyerProperties from "../clientappHairtechnicians/buyers/MyProperty";
import AppointmentMessages from "../clientappHairtechnicians/admin/appointments/AppointmentMessagePage";
import BookingMessages from "../clientappHairtechnicians/admin/bookings/BookingMessagesPage";
import ExpressedInterestMessages from "../clientappHairtechnicians/admin/interestexpressed/ExpressedInterestMessagesPage";
import GenerateInvoice from "../clientappHairtechnicians/admin/invoice/GenerateInvoice";
import InvoicePage from "../clientappHairtechnicians/admin/invoice/InvoicePage";
import GenerateHairStyleMenue from "./admin/HairStyleMenue/GenerateHairStyleMenue";
import HairStyleMenueASAStylistPage from "./admin/HairStyleMenue/HairStyleMenuePageAsAStylist";
import HairStyleMenuePage from "./admin/HairStyleMenue/HairStyleMenuePage";
import GenerateOrder from "../clientappHairtechnicians/admin/orders/GenerateOrder";
import OrdersPage from "../clientappHairtechnicians/admin/orders/OrdersPage";
import MakePayment from "../clientappHairtechnicians/payments/MakePayment";
import SendPaymentConfirmation from "../clientappHairtechnicians/payments/SendPaymentConfirmation";
import InvoiceByNumberPage from "../clientappHairtechnicians/admin/invoice/InvoiceByNumberPage";
import Help from "./help_hairtec";
import CollectPaymentNumber from "./payments/CollectPaymentNumber";
import MakePaymentRoot from "./payments/MakePaymentRoot";

class MainBodyHairTechnician extends Component {
  render() {
    return (
        <HashRouter>  
          <Route path="/helphairstyle" component={Help} />     
          <Route path="/appointmentmessageshairstyle" component={AppointmentMessages} /> 
          <Route path="/bookingmessageshairstyle" component={BookingMessages} /> 
          <Route path="/interestexpressedhairstyle" component={ExpressedInterestMessages} />
          <Route path="/buyerserviceshairstyle" component={BuyerService} /> 
          <Route path="/buyerappointmentshairstyle" component={BuyerAppointments} /> 
          <Route path="/BuyerPropertieshairstyle" component={BuyerProperties} /> 
          <Route path="/buyermessageshairstyle" component={BuyerMessages} /> 
          <Route path="/uploadedthankshairstyle" component={ThankYouUploaded} />
          <Route path="/assetdetailshairstyledev" component={AssetDetailsPageDev} />
          <Route path="/assetdetailshairstylelive" component={AssetDetailsPageLive} />          
          <Route path="/serviceshairstyle" component={Services} />
          <Route path="/menuehairstyle" component={Menue} />
          <Route path="/referencehairstyle" component={References} />
          <Route path="/bookinghairstyle" component={Booking} />
          <Route path="/appointmentshairstyle" component={Appointments} />
          <Route path="/interetshairstyle" component={Interets} /> 
          <Route path="/registerhairstyle" component={RegisterSimple} />
          <Route path="/changepasswordhairstyle" component={ChangePassword} />
          <Route path="/thankyouregisteredhairstyle" component={ThankyouAfterRegistration} /> 
          <Route path="/thankyoupasswordchangedhairstyle" component={ThankyouPasswordChanged} />           
          <Route path="/adminpagehairstyle" component={MyAdmin} />
          <Route path="/servicesubscriptionhairstyle" component={ServiceSubscription} />
          <Route path="/loginscreenhairstyle" component={Loginscreen} />
          <Route path="/loginhairstyle" component={Login} />
          <Route path="/contacthairstyle" component={Contact} />
          <Route path="/messageadminhairstyle" component={MessageMyAdmin} />
          <Route path="/usefulllinkshairstyle" component={UsefullLinks} />
          <Route path="/partnerservicehairstyle" component={Partnerservice} />
          <Route path="/logouthairstyle" component={Logout} /> 
          <Route path="/uploadassetshairstyle" component={UploadAssets} />
          <Route path="/uploadvideoshairstyle" component={UploadVideo} />
          <Route path="/uploadpictureshairstyle" component={UploadPictures} /> 
          <Route path="/generateinvoicehairstyle" component={GenerateInvoice} /> 
          <Route path="/invoicepagehairstyle" component={InvoicePage} />
          <Route path="/invoicebynumberpagehairstyle" component={InvoiceByNumberPage} />          
          <Route path="/generatemenuehairstyle" component={GenerateHairStyleMenue} /> 
          <Route path="/hairStylemenuepage" component={ HairStyleMenuePage} />
          <Route path="/menueasastylistpagehairstyle" component={HairStyleMenueASAStylistPage} />
          <Route path="/generateorderhairstyle" component={GenerateOrder} /> 
          <Route path="/orderspagehairstyle" component={OrdersPage} /> 
          <Route path="/makepaymenthairstyle" component={MakePayment} />
          <Route path="/sendpaymentconfirmationhairstyle" component={SendPaymentConfirmation} />
          <Route path="/hairandbeautypaymentnumber" component={CollectPaymentNumber} /> 
          <Route path="/makepaymenthb" component={MakePaymentRoot} />   
          
          <Route />
        </HashRouter>
     
    );
  }
}

export default withTranslation()(MainBodyHairTechnician);


//https://www.itsolutionstuff.com/post/password-and-confirm-password-validation-in-reactexample.html

import { useTranslation } from 'react-i18next';
import { React,useState, useEffect} from "react";
import axios from "axios";
import Accordion from 'react-bootstrap/Accordion';
import Card from "react-bootstrap/Card";
import GetUrl from "../../services/urlServiceCommodities";
import LocalStorageService from '../../../services/localStorageService';
import {Row, Col} from 'react-bootstrap';
//import VerifyToken from '../services/localStorageService';
 
  const GenerateInvoice = () => {
  const [title, setTitle] = useState('');
  const [fname, setFirstName] = useState('');
  const [lname, setLastName] = useState('');
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState('');
  const [businessName, setBusinessName] = useState(''); 
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [item1Name, setItem1Name] = useState('');
  const [item1Price, setItem1Price] = useState('');
  const [item2Name, setItem2Name] = useState('');
  const [item2Price, setItem2Price] = useState('');
  const [item3Name, setItem3Name] = useState('');
  const [item3Price, setItem3Price] = useState('');
  const [item4Name, setItem4Name] = useState('');
  const [item4Price, setItem4Price] = useState('');
  const [item5Name, setItem5Name] = useState('');
  const [item5Price, setItem5Price] = useState('');
  const [item6Name, setItem6Name] = useState('');
  const [item6Price, setItem6Price] = useState(''); 
  const [DishName, setDishName] = useState('');
  const [firstNameErrorMessage,  setErrorMessageFirstName] = useState('');
  const [lastNameErrorMessage,  setErrorMessageLastName] = useState('');
  const [titleErrorMessage,  setErrorMessageTitle] = useState('');
  const [customerPhoneNumberErrorMessage, setCustomerPhoneNumberErrorMessage] = useState('');
  const [contactEmailErrorMessage,  setErrorMessageContactEmail] = useState('');
  const [contactPhoneErrorMessage,  setErrorMessageContactPhone] = useState('');
  const [item1NameErrorMessage,  setErrorMessageItem1Name] = useState('');
  const [item1PriceErrorMessage,  setErrorMessageItem1Price] = useState('');
  const [item2NameErrorMessage,  setErrorMessageItem2Name] = useState('');
  const [item2PriceErrorMessage,  setErrorMessageItem2Price] = useState('');
  const [item3NameErrorMessage,  setErrorMessageItem3Name] = useState('');
  const [item3PriceErrorMessage,  setErrorMessageItem3Price] = useState('');
  const [item4NameErrorMessage,  setErrorMessageItem4Name] = useState('');
  const [item4PriceErrorMessage,  setErrorMessageItem4Price] = useState('');
  const [item5NameErrorMessage,  setErrorMessageItem5Name] = useState('');
  const [item5PriceErrorMessage,  setErrorMessageItem5Price] = useState('');
  const [item6NameErrorMessage,  setErrorMessageItem6Name] = useState('');
  const [item6PriceErrorMessage,  setErrorMessageItem6Price] = useState(''); 
  const [ errorMessageBusinessName,   setErrorMessageBusinessName] = useState('');  
  const [item1Qty,  setItem1Qty] = useState(''); 
  const [item1QtyErrorMessage,  setErrorMessageItem1Qty] = useState(''); 
  const [item2Qty,  setItem2Qty] = useState(''); 
  const [item2QtyErrorMessage,  setErrorMessageItem2Qty] = useState(''); 
  const [item3Qty,  setItem3Qty] = useState(''); 
  const [item3QtyErrorMessage,  setErrorMessageItem3Qty] = useState(''); 
  const [item4Qty,  setItem4Qty] = useState(''); 
  const [item4QtyErrorMessage,  setErrorMessageItem4Qty] = useState(''); 
  const [item5Qty,  setItem5Qty] = useState(''); 
  const [item5QtyErrorMessage,  setErrorMessageItem5Qty] = useState(''); 
  const [item6Qty,  setItem6Qty] = useState(''); 
  const [item6QtyErrorMessage,  setErrorMessageItem6Qty] = useState(''); 

  const [DishNameErrorMessage,  setErrorMessageDishName] = useState('');
  const [clickedButtonButNotPosted, setClickedButtonButNotPosted] = useState('');
  const [clickedNotPostedMessage, setClickedNotPostedMessage] = useState('');
  const [state, setState] = useState(false);
  const [note, setNote] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [isValid, setIsvalid] = useState(false);
  const [viewItem2, setViewItem2] = useState(false);
  const [viewItem3, setViewItem3] = useState(false);
  const [viewItem4, setViewItem4] = useState(false);
  const [viewItem5, setViewItem5] = useState(false);
  const [viewItem6, setViewItem6] = useState(false);
  const [viewItem2Button, setViewItem2Button] = useState(true);
  const [viewItem3Button, setViewItem3Button] = useState(false);
  const [viewItem4Button, setViewItem4Button] = useState(false);
  const [viewItem5Button, setViewItem5Button] = useState(false);
  const [viewItem6Button, setViewItem6Button] = useState(false);
  const [viewSubmitButton, setViewSubmitButton] = useState(true);
  const [viewCancel, setViewCancel] = useState(false);
  const [viewSave, setViewSave] = useState(false);
  const [viewAddMoreItem, setViewAddMoreItem] = useState(true);
  
 

  const { t } = useTranslation();

  function getApiPath () { 
    return GetUrl("generateInvoicehairstyle")
  };

  function loginToken (){
    return LocalStorageService("get", "token")
  }; 

 

  useEffect (() =>{
    setContactEmail(LocalStorageService("get", "userEmail"))
  }, )
//  const email = loginEmail();
 const token = loginToken();

  const isLoggedin = token!=null;

  function execute (event)  {
    event.preventDefault();
    //if (validate()) {
      console.info("Valid Form");
    // console.log('email: ', fghijVWXYZaklmnopqrUbcdestuvwxyz0192)
      handleSave(event);    
    // } else {
    //     setClickedButtonButNotPosted(true);
    //     setClickedNotPostedMessage(<p>{t("pages.contact.text.msginvalidform")} </p>);  //Data on the form is invalid or some fields are missing
    //     console.log("Data was not sent");     
    //   console.error("Invalid Form");
    //   return;
    // }
  };

  const  handleCancel = () =>{
    setViewCancel(false);
    setViewSubmitButton(true);
    setViewSave(false);
    setViewAddMoreItem(true);
      }
   
    

  const handleSubmit = () => {
    setViewCancel(true);
    setViewSubmitButton(false);
    setViewSave(true);
    setViewAddMoreItem(false);
  }

  const  handleSave = (event) =>{
    console.log('handleSave ran');
   event.preventDefault(); // 👈️ prevent page refresh
   const body ={
  title,
  fname,
  lname,
  customerPhoneNumber,
  businessName,
  contactEmail,  
  contactPhone, 
  item1Name, 
  item1Price,   
  item2Name, 
  item2Price,   
  item3Name, 
  item3Price,  
  item4Name, 
  item4Price,  
  item5Name, 
  item5Price,  
  item6Name,  
  item6Price,
  DishName,
  item1Qty,
  item2Qty,
  item3Qty,
  item4Qty,
  item5Qty,
  item6Qty
}; 
    
  validate();

   setClickedButtonButNotPosted(<p>{t("pages.contact.text.msginvalidform")} </p>);
     
      const API_PATH = getApiPath(); 
      if(token) {
      axios({
        method: "post",     
        url: `${API_PATH}`,
        data: body,
      })
        .then((result) => {
          if (result.status === 200) { 
            console.log({result})
           setMessageSent(true); 
           LocalStorageService("set", "email", contactEmail);
           LocalStorageService("set", "insertedId", result.data);        
           setBusinessName('');          
           setContactEmail('');
           setContactPhone('');
           setItem1Name('');
           setItem1Price('');
           setItem2Name('');
           setItem2Price('');
           setItem3Name('');
           setItem3Price('');
           setItem4Name('');
           setItem4Price('');
           setItem5Name('');
           setItem5Price('');
           setItem6Name('');
           setItem6Price('');         
           setDishName(''); 
           setItem1Qty('');
           setItem2Qty('');
           setItem3Qty('');
           setItem4Qty('');
           setItem5Qty('');
           setItem6Qty(''); 
          setFirstName('');
          setLastName('');
          setTitle('');
          setCustomerPhoneNumber('');      
          setErrorMessageFirstName('');
          setErrorMessageLastName('');
          setErrorMessageTitle('');
          setErrorMessageContactEmail('');
          setErrorMessageContactPhone('');
          setErrorMessageItem1Name('');
          setErrorMessageItem1Price('');
          setErrorMessageItem2Name('');
          setErrorMessageItem2Price('');
          setErrorMessageItem3Name('');
          setErrorMessageItem3Price('');
          setErrorMessageItem4Name('');
          setErrorMessageItem4Price('');
          setErrorMessageItem5Name('');
          setErrorMessageItem5Price('');
          setErrorMessageItem6Name('');
          setErrorMessageItem6Price('');          
          setErrorMessageDishName('');
          setErrorMessageItem1Qty('');
          setErrorMessageItem2Qty('');
          setErrorMessageItem3Qty('');
          setErrorMessageItem4Qty('');
          setErrorMessageItem5Qty('');
          setErrorMessageItem6Qty('');
          }
        })
        .catch(function (error) {    
          console.log(error);
        });  
      }   
    
  }

  const handleClick1 = ()=>{
   setViewItem2(true);
   setViewItem3Button(true);  
   setViewItem2Button(false);    
  } 
   const handleClick2 = ()=>{
    setViewItem3(true);
    setViewItem4Button(true);   
    setViewItem3Button(false);  
   }
   const handleClick3 = ()=>{
    setViewItem4(true); 
    setViewItem5Button(true);   
    setViewItem4Button(false);  
   }
   const handleClick4 = ()=>{
    setViewItem5(true);
    setViewItem6Button(true);   
    setViewItem5Button(false);   
   }
   const handleClick5 = ()=>{
    setViewItem6(true);
    setViewItem6Button(false);   
    setViewSubmitButton(true);   
   }

  


 const  validate = ()=> {
    let isValid = true;     

    if (!contactPhone) {
      isValid = false;     
      setErrorMessageContactPhone(<p>{t("pages.contact.text.phoneinvalidmsg")}</p>);
     }

     if (!businessName) {
      isValid = false;     
      setErrorMessageBusinessName(<p>Please select your business name</p>);
     }

     
    if (!contactEmail) {
      isValid = false;
      
      setErrorMessageContactEmail(<p>{t("pages.contact.text.emailinvalidmsg")}</p>);
    }

    if (typeof contactEmail !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(contactEmail)) {
        isValid = false;
        setErrorMessageContactEmail('')(<p>{t("pages.contact.text.emailpatterninvalidmsg")}</p>);
      }
    }

  };
  
  return (
   <div  className="content-akwaba">
    {isLoggedin && (  <form action="#">
        <div className="case-form" Style=""><p>Fill this form to create an invoice</p></div>   
        <Accordion defaultActiveKey="0">  
    <Card>
    <Accordion.Toggle as={Card.Header} eventKey="0">
    Your business info  
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="0">
      <Card.Body className="content-accordion" >
        <div className="col-md-9 offset-4">
      <div className="form-group">
        <label>Business name </label>     
       <select className="form-group  option-group" name= "businessName" id="businessName" value={businessName}  onChange={event => setBusinessName(event.target.value)} >
       <option value="">Select your business</option>
        <option value="FessFoodCorner">Jess's food corner</option>
        <option value="Cyrill">Cyrill</option>
        <option value="Afrostate">Afrostate</option>
        <option value="PerleDorange">PerleDorange</option>
        <option value="TresorDelice">Tresor delice</option> 
        <option value="Royal">Other</option>                                 
        </select>
        </div>
        <div className="text-danger">{errorMessageBusinessName}</div>


        <div className="form-group">
        <label>Your email</label>
        <input 
          readonly     
          id="contactEmail"
          name="contactEmail"
          type="text"
          value={contactEmail}
          onChange={event => setContactEmail(event.target.value)}
          placeholder="something@domain.com"
          className= "blured-input"
        />
          <div className="text-danger">{contactEmailErrorMessage}</div>
      </div>

      <div className="form-group">
        <label>Your phone</label>
         <input        
          id="contactPhone"
          name="contactPhone"
          type="text"
          value={contactPhone}
          onChange={event => setContactPhone(event.target.value)} 
          placeholder="123456789"
          className= "blured-input"
        />
          <div className="text-danger">{ contactPhoneErrorMessage}</div>
        </div>
        </div>
        </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="1">
    Your Customer's info  
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="1">
      <Card.Body className="content-accordion" >
        <div className="col-md-9 offset-4">
      <div className="form-group">
        <label>Title </label>     
        <select className="form-group  option-group" name= "title" id="title" value={title}  onChange={event => setTitle(event.target.value)} >
                <option value="">{t("pages.contact.default")}</option>
                <option value={t("pages.contact.sir")}>{t("pages.contact.sir")}</option>
                <option value={t("pages.contact.madam")}>{t("pages.contact.madam")}</option>
                <option value={t("pages.contact.unspecified")}>{t("pages.contact.unspecified")}</option>  
                <option value={t("pages.contact.other")}>{t("pages.contact.other")}</option>
                <option value="Royal">Royal</option>                                 
              </select>
        </div>
        <div className="text-danger">{titleErrorMessage}</div>


        <div className="form-group">
        <label>Customer's phone number</label>
        <input             
          id="customerPhoneNmuber"
          name="customerPhoneNmuber"
          type="text"
          value={customerPhoneNumber}
          onChange={event => setCustomerPhoneNumber(event.target.value)}
          placeholder="123455678"
          className= "blured-input"
        />
          <div className="text-danger">{customerPhoneNumberErrorMessage}</div>
      </div>

      <div className="form-group">
        <label>FisrtName</label>
         <input        
          id="fname"
          name="fname"
          type="text"
          value={fname}
          onChange={event => setFirstName(event.target.value)} 
          placeholder="First Name"
          className= "blured-input"
        />
          <div className="text-danger">{ firstNameErrorMessage}</div>
        </div>
        <div className="form-group">
        <label>LastName</label>
         <input        
          id="lname"
          name="lname"
          type="text"
          value={lname}
          onChange={event => setLastName(event.target.value)} 
          placeholder="Last Name"
          className= "blured-input"
        />
          <div className="text-danger">{ lastNameErrorMessage}</div>
        </div>

        </div>
        </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="2">
        Hair style info(You can add up to 6 items to your invoice)      
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="2">
      <Card.Body className="content-accordion"> 
      <div className="col-md-9 offset-4">
        <div className="form-group">
        <label>  Hair style Name</label>  
          <input
          id="DishName"
          name="DishName"
          type="text"
          value={DishName}
          onChange={event => setDishName(event.target.value)}          
          placeholder="Dish Name"
        />
          <div className="text-danger">{DishNameErrorMessage}</div>
        </div>
        <Row>
        <Col md={{ span: 9, offset: 0 }}> 
        <div className="form-group">
        <label>item1 Name </label>
        <input
          id="item1Name"
          name="item1Name"
          type="text"
          value={item1Name}
          onChange={event => setItem1Name(event.target.value)}          
          placeholder="Name"
        />
        <div className="text-danger">{item1NameErrorMessage}</div>
        </div>
        </Col>
        </Row>
         <Row>
        <Col md={{ span: 3, offset: 0}}>
         <div className="form-group">
        <label>item1 Price</label>
        <input
          id="item1Price"
          name="item1Price"
          type="text"
          value={item1Price}
          onChange={event =>setItem1Price(event.target.value)}          
          placeholder="Price"
        />
          <div className="text-danger">{ item1PriceErrorMessage}</div>
        </div>
        </Col>
        <Col md={{ span: 3, offset: 0 }}>
        <div className="form-group">
        <label>item1 Qty</label>
        <input
          id="item1Qty"
          name="item1Qty"
          type="text"
          value={item1Qty}
          onChange={event =>setItem1Qty(event.target.value)}          
          placeholder="Qty"
        />
          <div className="text-danger">{ item1QtyErrorMessage}</div>
        </div>
        </Col>       
        </Row>

        {viewItem2Button && viewAddMoreItem &&( <div>      
       <input
          type="button"
          value="Add more item or click submit"
          onClick={handleClick1}
         />
        </div>)}
    
        {viewItem2 && (
        <><Row>
                       <Col md={{ span: 9, offset: 0 }}>    
                        <div className="form-group">
                          <label>item2 Name </label>
                          <input
                            id="item2Name"
                            name="item2Name"
                            type="text"
                            value={item2Name}
                            onChange={event => setItem2Name(event.target.value)}
                            placeholder="Name" />
                          {/* <div className="text-danger">{setErrorMessageItem2Qty}</div> */}
                        </div>
                      </Col>
                    </Row><Row>
                        <Col md={{ span: 3, offset: 0 }}>
                          <div className="form-group">
                            <label>item2 Price</label>
                            <input
                              id="item2Price"
                              name="item2Price"
                              type="text"
                              value={item2Price}
                              onChange={event => setItem2Price(event.target.value)}
                              placeholder="Price" />
                            {/* <div className="text-danger">{item1PriceErrorMessage}</div> */}
                          </div>
                        </Col>

                        <Col md={{ span: 3, offset: 0 }}>
                          <div className="form-group">
                            <label>item2 Qty</label>
                            <input
                              id="item2Qty"
                              name="item2Qty"
                              type="text"
                              value={item2Qty}
                              onChange={event => setItem2Qty(event.target.value)}
                              placeholder="Qty" />
                            {/* <div className="text-danger">{item2QtyErrorMessage}</div> */}
                          </div>
                        </Col>
                      </Row></>
        )}

      {viewItem3Button && viewAddMoreItem &&( <div>      
       <input
          type="button"
          value="Add more item or click submit"
          onClick={handleClick2}
         />
        </div>)}
    

        {viewItem3 && (<><Row>
          <Col md={{ span: 9, offset: 0 }}>    
                      <div className="form-group">
                        <label>item3 Name </label>
                        <input
                          id="item3Name"
                          name="item3Name"
                          type="text"
                          value={item3Name}
                          onChange={event => setItem3Name(event.target.value)}
                          placeholder="Name" />
                        {/* <div className="text-danger">{setErrorMessageItem3Qty}</div> */}
                      </div>
                    </Col>
                  </Row><Row>
                      <Col md={{ span: 3, offset: 0 }}>
                        <div className="form-group">
                          <label>item3 Price</label>
                          <input
                            id="item3Price"
                            name="item3Price"
                            type="text"
                            value={item3Price}
                            onChange={event => setItem3Price(event.target.value)}
                            placeholder="Price" />
                          {/* <div className="text-danger">{item3PriceErrorMessage}</div> */}
                        </div>
                      </Col>

                      <Col md={{ span: 3, offset: 0 }}>
                        <div className="form-group">
                          <label>item3 Qty</label>
                          <input
                            id="item3Qty"
                            name="item3Qty"
                            type="text"
                            value={item3Qty}
                            onChange={event => setItem3Qty(event.target.value)}
                            placeholder="Qty" />
                          {/* <div className="text-danger">{item3QtyErrorMessage}</div> */}
                        </div>
                      </Col>
                    </Row></>
        )}
      {viewItem4Button && viewAddMoreItem &&( <div>      
       <input
          type="button"
          value="Add more item or click submit"
          onClick={handleClick3}
         />
        </div>)}


        {viewItem4 && (<><Row>
          <Col md={{ span: 9, offset: 0 }}>    
                      <div className="form-group">
                        <label>item4 Name </label>
                        <input
                          id="item4Name"
                          name="item4Name"
                          type="text"
                          value={item4Name}
                          onChange={event => setItem4Name(event.target.value)}
                          placeholder="Name" />
                        {/* <div className="text-danger">{setErrorMessageItem4Qty}</div> */}
                      </div>
                    </Col>
                  </Row><Row>
                      <Col md={{ span: 3, offset: 0 }}>
                        <div className="form-group">
                          <label>item4 Price</label>
                          <input
                            id="item4Price"
                            name="item4Price"
                            type="text"
                            value={item4Price}
                            onChange={event => setItem4Price(event.target.value)}
                            placeholder="Price" />
                          {/* <div className="text-danger">{item4PriceErrorMessage}</div> */}
                        </div>
                      </Col>

                      <Col md={{ span: 3, offset: 0 }}>
                        <div className="form-group">
                          <label>item4 Qty</label>
                          <input
                            id="item4Qty"
                            name="item4Qty"
                            type="text"
                            value={item4Qty}
                            onChange={event => setItem4Qty(event.target.value)}
                            placeholder="Qty" />
                          {/* <div className="text-danger">{item4QtyErrorMessage}</div> */}
                        </div>
                      </Col>
                    </Row></>
        )}
      {viewItem5Button && viewAddMoreItem &&( <div>      
       <input
          type="button"
          value="Add more item or click submit"
          onClick={handleClick4}
         />
        </div>)}
        {viewItem5 && (<><Row>
          <Col md={{ span: 9, offset: 0 }}>    
                      <div className="form-group">
                        <label>item5 Name </label>
                        <input
                          id="item5Name"
                          name="item5Name"
                          type="text"
                          value={item5Name}
                          onChange={event => setItem5Name(event.target.value)}
                          placeholder="Name" />
                        {/* <div className="text-danger">{setErrorMessageItem5Qty}</div> */}
                      </div>
                    </Col>

                  </Row><Row>
                      <Col md={{ span: 3, offset: 0 }}>
                        <div className="form-group">
                          <label>item5 Price</label>
                          <input
                            id="item5Price"
                            name="item5Price"
                            type="text"
                            value={item5Price}
                            onChange={event => setItem5Price(event.target.value)}
                            placeholder="Price" />
                          {/* <div className="text-danger">{item5PriceErrorMessage}</div> */}
                        </div>
                      </Col>

                      <Col md={{ span: 3, offset: 0 }}>
                        <div className="form-group">
                          <label>item5 Qty</label>
                          <input
                            id="item5Qty"
                            name="item5Qty"
                            type="text"
                            value={item5Qty}
                            onChange={event => setItem5Qty(event.target.value)}
                            placeholder="Qty" />
                          {/* <div className="text-danger">{item5QtyErrorMessage}</div> */}
                        </div>
                      </Col>
                    </Row></>
        )}
      {viewItem6Button && viewAddMoreItem &&( <div>      
       <input
          type="button"
          value="Add more item or click submit"
          onClick={handleClick5}
         />
        </div>)}
        {viewItem6 && (<><Row>
          <Col md={{ span: 9, offset: 0 }}>    
                      <div className="form-group">
                        <label>item6 Name </label>
                        <input
                          id="item6Name"
                          name="item6Name"
                          type="text"
                          value={item6Name}
                          onChange={event => setItem6Name(event.target.value)}
                          placeholder="Name" />
                        {/* <div className="text-danger">{setErrorMessageItem6Qty}</div> */}
                      </div>
                    </Col>
                  </Row><Row>
                      <Col md={{ span: 3, offset: 0 }}>
                        <div className="form-group">
                          <label>item6 Price</label>
                          <input
                            id="item6Price"
                            name="item6Price"
                            type="text"
                            value={item6Price}
                            onChange={event => setItem6Price(event.target.value)}
                            placeholder="Price" />
                          {/* <div className="text-danger">{item6PriceErrorMessage}</div> */}
                        </div>
                      </Col>

                      <Col md={{ span: 3, offset: 0 }}>
                        <div className="form-group">
                          <label>item6 Qty</label>
                          <input
                            id="item6Qty"
                            name="item6Qty"
                            type="text"
                            value={item6Qty}
                            onChange={event => setItem6Qty(event.target.value)}
                            placeholder="Qty" />
                          {/* <div className="text-danger">{item6QtyErrorMessage}</div> */}
                        </div>
                      </Col>
                    </Row></>
        )} 

        <Row>
        {viewSubmitButton &&(
          <Col md={{ span: 6, offset: 0 }}>
        <div>
        <input
         className="btn btn-primary"
          type="submit"
           onClick={(e) =>  {handleSubmit(e)}}
          defaultValue={t("pages.contact.text.submit")}
          />
        </div> 
        </Col>    
             )} 
         </Row>
   
        <Row>         
      {viewCancel && (
      <Col md={{ span: 3, offset: 0 }} className="invoice-save-cancel-panel">
      <div><button type="submit" onClick={handleCancel} className ="invoice-buttons">Cancel ❌</button></div>
        </Col>)}
      
      {viewSave && (
      <Col md={{ span: 3, offset: 0 }} className="invoice-save-cancel-panel">
      <div><button type="submit" onClick={(e) => {execute(e)}} className ="invoice-buttons">Confirm ✔</button></div>
      </Col>)}      
      </Row>
      </div>
          
        </Card.Body>
    </Accordion.Collapse>
  </Card>

</Accordion>      
{clickedButtonButNotPosted && (
        <div className="text-danger">
          {clickedNotPostedMessage} <br />                  
        </div>
         )}
      </form>)} 
    </div>
  );
};

export default GenerateInvoice;




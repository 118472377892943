import React, { Component } from "react";
 
class Help extends Component {
  render() {
    return (
      <div className ="container" >
   <p><b>Welcome to Manblay hair style Help Center</b></p> 
<p></p>
Navigating Your Hair Experience
Whether you're a new customer or a loyal patron, our Help Center is designed to guide you through the salon experience. Explore the following sections for valuable information:

<p><b>1. Hair Services and Pricing</b></p>
The range of services we offer and the corresponding prices are set by each hair technician. They should be contacted individually for the detailed service menu to ensures transparency, so you know exactly what to expect during your visit.

<p><b>2. Appointment Booking</b></p>
Booking an appointment with us is quick and easy. Contact your chosen stylist or navigate to their detailed page and follow the steps to reserve your spot:

<p><b>3. Hair etiquette and Policies</b></p>
Please contact us to familiarise yourself with our etiquette and policies to ensure a pleasant experience for both you and our staff. As a promotor, we recognise that each stylist may have their own This includes guidelines on late arrivals, cancellations, and more.

<p><b>Frequently Asked Questions</b></p>
Q: How do I know which stylist is right for me?<br/>
A: Explore our Stylist Profiles if there is to learn more about each stylist's specialties, experience, and personal style. You can also contact us for personalized recommendations.
<br/>
Q: Can I bring a reference picture for my haircut?<br/>
A: Absolutely! We encourage you to bring pictures or inspiration for your desired hairstyle. It helps our stylists understand your vision.
<br/>
Q: Do you offer hair care product recommendations?<br/>
A: Yes! Our stylists are knowledgeable about various hair care products. Feel free to ask for personalized recommendations based on your hair type and needs.
<br/>
<p><b>Contact Us</b></p>
If you have any questions or need assistance, our customer support team is here to help.
<br/>
Live Chat: [in progress]<br/>
Email: info.manblay@gmail.com<br/>
Phone: 07377040394<br/>
<p><b>Feedback</b></p>
We value your feedback! If you have suggestions for improvement, want to share your salon experience, or have ideas for new services, please let us know.
<p></p>
Thank you for choosing Manblay hair style. We're dedicated to providing a personalized and enjoyable experience for all our clients.
<p></p>
      </div>
    );
  }
}
 
export default Help;

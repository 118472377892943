import React from 'react';
import styled from 'styled-components';
import Burger from './Burger';
import logo from '../logo/logo.png';
import {NavLink, HashRouter } from "react-router-dom";
import { useTranslation  } from "react-i18next";
import { Row, Col } from 'react-bootstrap';

const Nav = styled.nav`
  width: auto;
  height: auto;     
  border-bottom: 2px solid #f1f1f1;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  .logo {
    padding: 15px 0;
    width: auto;   
  }  
`

const NavbarHairTechnician = () => {
  return (   
    <div className="hairTechNav">
    <Nav > 
      <Burger />
    </Nav>
    </div>
  )
}
 export default NavbarHairTechnician

import React, { Component } from "react";

 
class HelpCatering extends Component {
  render() {  
    return (
      <div className ="container" >
    <p>Welcome to Manblay catering</p>
<p>About Us</p>
<span>At Manblay catering, we believe in turning moments into memories through the art of exquisite catering. With a passion for culinary excellence and a commitment to impeccable service, we have been delighting clients with our catering expertise.

<p><b>1. Our Services </b><br/></p>
1. Catering for Every Occasion
<p><b>1. Catering for Every Occasion</b><br/></p>
No matter the event, we have the perfect culinary solutions for you. From weddings and corporate events to private parties and special celebrations, our diverse menu options cater to all tastes and preferences.


<p><b>2. Customizable Menus</b><br/></p>
We understand that every event is unique. That's why we offer customizable menus to suit your specific needs. Our experienced chefs will work with you to create a menu that reflects your style and satisfies the tastes of your guests.


<p><b>3. Fresh and Locally Sourced Ingredients</b><br/></p>

Quality is at the heart of what we do. We source fresh, locally produced ingredients to ensure that every dish is a masterpiece. Our commitment to quality extends from our kitchen to your table.

<p><b>4. Professional Staff</b><br/></p>

Our team of professional and courteous staff is dedicated to making your event seamless and stress-free. From setup to cleanup, we handle every detail so you can focus on enjoying the occasion.

<p><b>How It Works</b><br/>
Consultation:<br/> Begin with a consultation to discuss your event, preferences, and dietary requirements.

Menu Planning:<br/> Collaborate with our chefs to create a personalized menu tailored to your event.

Confirmation: <br/>Finalize the details and confirm your catering order with our team.

Event Day: <br/> Relax and enjoy your event while our team takes care of the catering, ensuring a memorable experience for you and your guests.
</p>
<p><b>Testimonials</b><br/>
"The catering for our wedding was absolutely perfect. The food was delicious, the presentation was stunning, and the service was impeccable. Thank you, [Your Catering Business Name], for making our special day even more memorable." - [Happy Customer]
</p>
<p><b>Contact Us</b><br/>
Ready to elevate your event with exceptional catering? Contact us today to discuss your requirements and let us create a culinary experience that will leave a lasting impression.
</p>
<p><b>Contact Information</b><br/>
info.manblay@gmail.com
</p>
</span>

      </div>
    );
  }
}
 
export default HelpCatering;
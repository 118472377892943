import React, { Component } from "react";
import {NavLink, HashRouter } from "react-router-dom";
import { useTranslation  } from "react-i18next";
import { Redirect } from "react-router";
import { Row, Col } from "react-bootstrap";
import LocalStorageService from '../../services/localStorageService';
import AppName from '../services/getAppName';

function BuyerServices(){
  AppName();
  function loginEmail (){
    return LocalStorageService("get", "userEmail"); 
  }; 
  
  function getToken (){
    return LocalStorageService("get", "token"); 
  }; 

 const email = loginEmail();

 const token = getToken();

  const isLoggedin = token!=null;

  const { t } = useTranslation(); 
      return (       
        <div className="container">    
     
     <div className="akwaba-content-admin"> 
           <Row>
            <Col md={{ span: 2, offset: 4 }}>
              {" "}             
              <p></p>
            </Col>
          </Row>
          <Row>
            <HashRouter>
              <Col md={{ span: 4 , offset: 3}}>
                {" "}
               {isLoggedin && (<div>
                    <div></div> 
                  <NavLink to="/homehairs">
                    <div className="admin-link-to-post">
                    <b>Home</b> <br/>                            
                    </div>
                  </NavLink> 
                </div>)}

                {isLoggedin && (<div>
                    <div></div> 
                  <NavLink to="/buyermessageshairstyle">
                    <div className="admin-link-to-post">
                    <b>My messages</b> <br/>                            
                    </div>
                  </NavLink> 
                </div>)}

                {isLoggedin && (<div>
                    <div></div> 
                  <NavLink to="/buyerappointmentshairstyle">
                    <div className="admin-link-to-post">
                    <b>My appointments</b> <br/>                            
                    </div>
                  </NavLink> 
                </div>)}

                {isLoggedin && (<div>
                    <div></div> 
                  <NavLink to="/buyerpropertieshairstyle">
                    <div className="admin-link-to-post">
                    <b>My bookings</b> <br/>                            
                    </div>
                  </NavLink> 
                </div>)}

                {isLoggedin && (<div>
                    <div></div> 
                  <NavLink to="/seemyinvoiceshairstyle">
                    <div className="admin-link-to-post">
                    <b>My invoices</b> <br/>                            
                    </div>
                  </NavLink> 
                </div>)}

                {!isLoggedin && (<div>
                  <span>Please Login. If not registered yet please register and login</span>
                  </div>)}
                
              </Col>
             
            </HashRouter>
          </Row>     
          <Row>
            <Col md={{ span: 2, offset: 4 }}>
              {" "}             
              <p></p>
            </Col>
          </Row>         
        </div>
   
    </div>
    )
  }
 
export default (BuyerServices);
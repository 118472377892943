import React, { Component } from "react";
import {NavLink, HashRouter } from "react-router-dom";
import { withTranslation  } from "react-i18next";
import { Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import LocalStorageService from "../services/localStorageService";
class Footer extends Component {
  render() {
    const { t } = this.props;
    var service = LocalStorageService("get","servicecategory");    
    return (
      <div className ="footer-akwaba">
        <p>{t("footer.poweredby")}</p>      
       <div>         
<Container>  
  <Row>
  <HashRouter> 
    <Col md={{ span: 2, offset: 1}}> <NavLink to="/askus"><span className="akwaba-tiles-navlinks">{t("footer.ask")}</span></NavLink></Col>
    {service== "catering" &&(<Col md={{ span: 2, offset: 0 }}><NavLink to="/registersimple"><span className="akwaba-tiles-navlinks">{t("footer.join")}</span></NavLink></Col>)}   
    {service== "assembling" &&(<Col md={{ span: 2, offset: 0 }}><NavLink to="/registerassembling"><span className="akwaba-tiles-navlinks">{t("footer.join")}</span></NavLink></Col>)} 
    {service== "commodities" &&(<Col md={{ span: 2, offset: 0 }}><NavLink to="/registersimplecommodities"><span className="akwaba-tiles-navlinks">{t("footer.join")}</span></NavLink></Col>)} 
    {service== "hairstyle" &&(<Col md={{ span: 2, offset: 0 }}><NavLink to="/registerhairstyle"><span className="akwaba-tiles-navlinks">{t("footer.join")}</span></NavLink></Col>)} 
    <Col md={{ span: 2, offset: 0 }}><NavLink to="/followus"><span className="akwaba-tiles-navlinks">{t("footer.follow")}</span></NavLink></Col> 
    <Col md={{ span: 2, offset: 0 }}><NavLink to="/disclaimer"><span className="akwaba-tiles-navlinks">{t("footer.disclaimer")}</span></NavLink></Col>  
   {service== null &&( <Col md={{ span: 2, offset: 0 }}><NavLink to="/help"><span className="akwaba-tiles-navlinks">{t("footer.help")}</span></NavLink></Col> )}  
   {service== "catering" &&( <Col md={{ span: 2, offset: 0 }}><NavLink to="/helpcatering"><span className="akwaba-tiles-navlinks">{t("footer.help")}</span></NavLink></Col>)} 
   {service== "assembling" &&(   <Col md={{ span: 2, offset: 0 }}><NavLink to="/helpassembling"><span className="akwaba-tiles-navlinks">{t("footer.help")}</span></NavLink></Col> )}
   {service== "commodities" &&(  <Col md={{ span: 2, offset: 0 }}><NavLink to="/helpcommodities"><span className="akwaba-tiles-navlinks">{t("footer.help")}</span></NavLink></Col> )}
   {service== "hairstyle" &&(  <Col md={{ span: 2, offset: 0 }}><NavLink to="/helphairstyle"><span className="akwaba-tiles-navlinks">{t("footer.help")}</span></NavLink></Col> )}
  </HashRouter> 
  </Row>  
</Container> 
       </div>
      </div>
    );
  }
}
 
export default withTranslation()(Footer);
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";
import Services from "./pages/Services";
import Contact from "./pages/messages/SendContactMessage";
import Menue from "./pages/Menue";
import References from "./pages/References";
import Booking from "./pages/Booking";
import Appointments from "./pages/Appointments";
import Interets from "./pages/Interets";
import RegisterSimple from "./registerlogin/RegisterSimple";
import ChangePassword from "./registerlogin/ChangePassword";
import Loginscreen from "./registerlogin/LoginScreen";
import Login from "./registerlogin/Login";
import MessageMyAdmin from "./admin/myAdmin/MessageAdminPage";
import MyAdmin from "./admin/myAdmin/MyDashBoard";
import ServiceSubscription from "./admin/myAdmin/ServiceSubscription";
import Logout from "./registerlogin/Logout";
import Partnerservice from "./partners/Partnerservice";
import UsefullLinks from "./admin/myAdmin/UsefullLinks";
import ThankYouUploaded from "./uploadMedia/ThankyouAfterUploaded";
import UploadAssets from "./uploadMedia/UploadTextInputs";
import AssetDetailsDev from "./loadPages/AssetDetailPageDev";
import AssetDetailsLive from "./loadPages/AssetDetailPageLive";
import UploadVideo from "./uploadMedia/UploadVideo";
import UploadPictures from "./uploadMedia/UploadPictures";
import ThankyouAfterRegistration from "./registerlogin/ThankyouAfterRegistration";
import ThankyouPasswordChanged from  "./registerlogin/ThankyouPasswordChanged";
import BuyerService from "./buyers/BuyerServices";
import BuyerMessages from "./buyers/MyMessagePage";
import BuyerAppointments from "./buyers/MyAppointments";
import BuyerProperties from "./buyers/MyProperty";
import AppointmentMessages from "./admin/appointments/AppointmentMessagePage";
import BookingMessages from "./admin/bookings/BookingMessagesPage";
import ExpressedInterestMessages from "./admin/interestexpressed/ExpressedInterestMessagesPage";
import GenerateInvoice from "./admin/invoice/GenerateInvoice";
import InvoicePage from "./admin/invoice/InvoicePage";
import GenerateProductMenue from "./admin/foodMenue/FoodMenuePage";
import ProductMenueASAChefPage from "./admin/foodMenue/FoodMenuePageAsAChef";
import ProductMenuePage from "./admin/foodMenue/FoodMenuePage";
import GenerateOrder from "./admin/orders/GenerateOrder";
import OrdersPage from "./admin/orders/OrdersPage";
import MakePayment from "./payments/MakePayment";
import SendPaymentConfirmation from "./payments/SendPaymentConfirmation";
import InvoiceByNumberPage from "./admin/invoice/InvoiceByNumberPage";
import Help from "./help_catering"
import MakePaymentRoot from "./payments/MakePaymentRoot";
import CollectPaymentNumber from "./payments/CollectPaymentNumber";

class MainBodyCatering extends Component {
  render() {
    return (
      <div>        
        <HashRouter> 
          <Route path="/helpcatering" component={Help} />       
          <Route path="/appointmentmessagescatering" component={AppointmentMessages} /> 
          <Route path="/bookingmessagescatering" component={BookingMessages} /> 
          <Route path="/interestexpressedcatering" component={ExpressedInterestMessages} />
          <Route path="/buyerservicescatering" component={BuyerService} /> 
          <Route path="/buyerappointmentscatering" component={BuyerAppointments} /> 
          <Route path="/BuyerPropertiescatering" component={BuyerProperties} /> 
          <Route path="/buyermessagescatering" component={BuyerMessages} /> 
          <Route path="/uploadedthankscatering" component={ThankYouUploaded} />
          <Route path="/assetdetailscateringdev" component={AssetDetailsDev} />
          <Route path="/assetdetailscateringlive" component={AssetDetailsLive} />          
          <Route path="/servicescatering" component={Services} />
          <Route path="/menuecatering" component={Menue} />
          <Route path="/referencecatering" component={References} />
          <Route path="/bookingcatering" component={Booking} />
          <Route path="/appointmentscatering" component={Appointments} />
          <Route path="/interetscatering" component={Interets} /> 
          <Route path="/registersimplecatering" component={RegisterSimple} />
          <Route path="/changepasswordcatering" component={ChangePassword} />
          <Route path="/thankyouregisteredcatering" component={ThankyouAfterRegistration} /> 
          <Route path="/thankyoupasswordchangedcatering" component={ThankyouPasswordChanged} />           
          <Route path="/adminpagecatering" component={MyAdmin} />
          <Route path="/servicesubscriptioncatering" component={ServiceSubscription} />
          <Route path="/loginscreencatering" component={Loginscreen} />
          <Route path="/logincatering" component={Login} />
          <Route path="/contactcatering" component={Contact} />
          <Route path="/messageadmincatering" component={MessageMyAdmin} />
          <Route path="/usefulllinkscatering" component={UsefullLinks} />
          <Route path="/partnerservicecatering" component={Partnerservice} />
          <Route path="/logoutcatering" component={Logout} /> 
          <Route path="/uploadassetscatering" component={UploadAssets} />
          <Route path="/uploadvideoscatering" component={UploadVideo} />
          <Route path="/uploadpicturescatering" component={UploadPictures} /> 
          <Route path="/generateinvoicecatering" component={GenerateInvoice} /> 
          <Route path="/invoicepagecatering" component={InvoicePage} />
          <Route path="/invoicebynumberpagecatering" component={InvoiceByNumberPage} />          
          <Route path="/generateproductmenuecatering" component={GenerateProductMenue} /> 
          <Route path="/productmenuepagecatering" component={ ProductMenuePage} />
          <Route path="/productmenueasachefpagecatering" component={ProductMenueASAChefPage} />
          <Route path="/generateordercatering" component={GenerateOrder} /> 
          <Route path="/orderspagecatering" component={OrdersPage} /> 
          <Route path="/makepaymentcatering" component={MakePayment} />
          <Route path="/sendpaymentconfirmationcatering" component={SendPaymentConfirmation} />          
          <Route path="/makepaymentcat" component={MakePaymentRoot} />
          <Route path="/cateringpaymentnumber" component={CollectPaymentNumber} />          
          <Route />
        </HashRouter>
      </div>
    );
  }
}

export default withTranslation()(MainBodyCatering);

import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";
import Homecatering from "./clientappCatering/HomeCatering";
import HomeHairs from "./clientappHairtechnicians/HomeHairs";
import HomeCommodities from "./clientappCommodities/HomeCommodities";
import HomeAssemblingTechnician from "./clientappAssemblingTechnicians/HomeAssemblingTechnician";
import HomeDashboard from"./HomeDashboard";
import MainBodyHairTechnician from "./clientappHairtechnicians/MainBodyHairTechnician";
import MainBodyAssembling from "./clientappAssemblingTechnicians/MainBodyAssembling";
import MainBodyCommodities from "./clientappCommodities/MainBodyCommodities";
import MainBodyCatering from "./clientappCatering/MainBodyCatering";
import Disclaimer from "./footer/disclaimer";
import Help from "./footer/help";
import Follow from "./footer/followus";
import Join from "./footer/joinus";
import Ask from "./footer/askus";

class Main extends Component {
  render() {
    return (
      <div>      
        <MainBodyHairTechnician/>
        <MainBodyAssembling/>
        <MainBodyCommodities/>
        <MainBodyCatering/>
        <HashRouter>
          <Route exact path="/" component={HomeDashboard} />
          <Route exact path="/home" component={HomeDashboard} />
          <Route path="/homedashboard" component={HomeDashboard} />  
          <Route path="/disclaimer" component={Disclaimer} />
          <Route path="/help" component={Help} />          
          <Route path="/followus" component={Follow} />
          <Route path="/joinus" component={Join} />
          <Route path="/askus" component={Ask} />
          <Route path="/homehairs" component={HomeHairs} />
          <Route path="/homecatering" component={Homecatering} /> 
          <Route path="/homecommodities" component={HomeCommodities} />
          <Route path="/homeassembling" component={HomeAssemblingTechnician} />         
         <Route />
        </HashRouter>
      </div>
    );
  }
}

export default withTranslation()(Main);
